.rodape{
	padding-top:10px;
	padding-bottom:10px;

	.h1{
		color: #C51D21;
		font-family: 'LTOksana';
		font-size:36px;
		line-height:1.3;

		strong{
			font-size:1.3em;
		}
	}
}

#mapaFooter{
	position:relative;
	padding-bottom: (238 / 472) * 100%;

	iframe{
		position:absolute;
		width:100%;
		height:100%
	}
}

.flx-footer{
	display:flex;
	flex-wrap: wrap;

	.flex-column{
		display:flex;
		flex-direction: column;

		@media (max-width:$screen-md-max){
			flex-direction: row;
			margin-right: 10px;

			.circle-floating{
				margin-bottom: 0px;
				margin-left: 10px;
			}
		}

		a{
			margin-bottom: 5px
		}
	}

	p{
		@include clearfix;
		padding-left:40px;

		.circle-floating{
			float:left;
			margin-left:-40px;
			margin-right:10px;
		}
	}
}

.circle-floating{
	width:30px;
	height:30px;
	display: inline-block;
	line-height:30px;
	text-align:center;
	font-size: 18px;
	background: #FF0000;
	border-radius: 100%;

	.fa{
		color:#FFF;
	}
}

.whatsapp-button{
	position: fixed;
	color: #FFF;
	background: #16B536;
	border-radius: 50%;
	position: fixed;
	bottom: 15px;
	left:15px;
	z-index: 999;
	display: block;
	$f: 30;

	font-size: #{$f}px;

	$w: $f * 1.5;

	$size: ($w / $f) * 1em;

	width: $size;
	height: $size;
	line-height: $size;
	text-align: center;
	overflow: hidden;
	box-shadow: 0 0 8px #000;

	$text-shadow: -1px 1px #139B2F;

	@for $i from 2 through $w {
		$text-shadow: $text-shadow, -#{$i}px #{$i}px #139B2F;
	}

	text-shadow: $text-shadow;
	transition:all 0.3s linear;

	&:hover{
		color:#FFF;
		text-decoration: none;
		font-size: #{$f * 1.333}px;
	}
}
