#banner{
	overflow:hidden;
	z-index:0;

	&:before{
		content:'';
		display:block;
		position:absolute;
		width:100%;
		height:100%;
		background: #fff url(#{$imgs}/ring.svg) center center no-repeat;
		z-index:999;
	}

	&.nao-carregado{
		height:600px;
		display:block;
	}

	&.carregado{
		&:before{
			animation:fadeOut 0.6s forwards;
		}
	}

	.row{
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		// min-height:578px;
	}

	.banner{
		background-position:center top;
		background-size:cover;
		transition:padding 0.3s linear;
		width:100vw;

		padding-top:100px;
	}

	& > .banner{
		display: none;

		&.first{
			display: block;
		}
	}

	.titulo{
		margin-bottom: 10px;
	}

	.pessoa,
	.titulo,
	.texto{
		opacity:0;
	}

	.active {
		.pessoa{
			animation-name: fadeInRight;
			animation-duration: 1s;
			animation-delay: 0.4s;
			animation-fill-mode: forwards;
		}

		.titulo{
			animation-name: fadeInDown;
			animation-duration: 1s;
			animation-delay: 0.4s;
			animation-fill-mode: forwards;
		}

		.texto{
			animation-name: fadeInUp;
			animation-duration: 1s;
			animation-delay: 0.4s;
			animation-fill-mode: forwards;
		}
	}

	.owl-controls{
		position:absolute;
		width:100%;
		height:100%;
		text-align:center;
		bottom:10px;
		display:flex;
		align-items:center;
		z-index:999;
	}

	.owl-nav{
		width:100%;
		padding:0 15px;
		@include clearfix;

		& > *{
			position:relative;
			z-index:3;
			cursor:pointer;
		}
	}

	.owl-prev{
		float:left;
	}

	.owl-next{
		float:right;
	}
}

#banner-principal{
  &.loading{
    min-height:calc(100vh - 100px);
    &:before{
      content:'';
      display: block;
      background: url(#{$imgs}/ring.svg) #FFF center center no-repeat;
      width:100%;
      height:100%;
      top:0;
      left:0;
      position:absolute;
      z-index:1;
    }
  }

	.item.active{
		@extend .animated;
	}

	.carousel-indicators{
		position:absolute;
		bottom:10px;
		z-index:0;

		li{
			width:18px;
			height:18px;
			background: rgba(#FFF,0.5);
			border:1px solid rgba(#FFF,0.5);

			&.active{
				border-color:#FFF;
				background: #000;
				-webkit-animation: rubberBand 0.6s linear;
				animation: rubberBand 0.6s linear;
			}
		}
	}
}

#banner-empresa{
  .carousel-indicators{
		position:absolute;
		bottom:10px;
		z-index:0;

		li{
			width:12px;
			height:12px;
			background: rgba(#FFF,0.5);
      border: none;

			&.active{
				border-color:#FFF;
				background: #fff;
				-webkit-animation: rubberBand 0.6s linear;
				animation: rubberBand 0.6s linear;
			}
		}
	}
}

.carousel.reverse .carousel-inner {
  height: 100%;
  width: 100%;

  > .item {
    width: auto;
    transition: 0.6s ease-in-out top;
    @media all and (transform-3d), (-webkit-transform-3d) {
      transition: 0.6s ease-in-out;
      &.next,
      &.active.right {
        transform: translate3d(0, -100%, 0);
        top: 0;
      }
      &.prev,
      &.active.left {
        transform: translate3d(0, 100%, 0);
        top: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        transform: translate3d(0, 0, 0);
        top: 0;
      }
    }
  }
  > .active,
  > .next,
  > .prev {
    display: block;
  }
  > .active {
    top: 0;
  }
  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }
  > .next {
    top: 100%;
  }
  > .prev {
    top: -100%;
  }
  > .next.left,
  > .prev.right {
    top: 0;
  }
  > .active.left {
    top: -100%;
  }
  > .active.right {
    top: 100%;
  }
}

.carousel.alternate .carousel-inner {
  height: 100%;
  width: 100%;

  > .item {
    width: auto;
    transition: 0.6s ease-in-out top;
    @media all and (transform-3d), (-webkit-transform-3d) {
      transition: 0.6s ease-in-out;
      &.next,
      &.active.right {
        transform: translate3d(0, 100%, 0);
        top: 0;
      }
      &.prev,
      &.active.left {
        transform: translate3d(0, -100%, 0);
        top: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        transform: translate3d(0, 0, 0);
        top: 0;
      }
    }
  }
  > .active,
  > .next,
  > .prev {
    display: block;
  }
  > .active {
    top: 0;
  }
  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }
  > .next {
    top: 100%;
  }
  > .prev {
    top: -100%;
  }
  > .next.left,
  > .prev.right {
    top: 0;
  }
  > .active.left {
    top: -100%;
  }
  > .active.right {
    top: 100%;
  }
}

.carousel.inverse .carousel-inner {
  height: 100%;
  width: 100%;

  > .item {
    width: auto;
    transition: 0.6s ease-in-out top;
    @media all and (transform-3d), (-webkit-transform-3d) {
      transition: 0.6s ease-in-out;
      &.next,
      &.active.right {
        transform: translate3d(-100%, 0, 0);
        top: 0;
      }
      &.prev,
      &.active.left {
        transform: translate3d(100%, 0, 0);
        top: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        transform: translate3d(0, 0, 0);
        top: 0;
      }
    }
  }
  > .active,
  > .next,
  > .prev {
    display: block;
  }
  > .active {
    top: 0;
  }
  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }
  > .next {
    top: 100%;
  }
  > .prev {
    top: -100%;
  }
  > .next.left,
  > .prev.right {
    top: 0;
  }
  > .active.left {
    top: -100%;
  }
  > .active.right {
    top: 100%;
  }
}
