#fotos{
	.album{
		margin-left:auto;
		margin-right:auto;
		position:relative;
	}

	.picture{
		position:relative;
		padding-bottom: (246 / 324) * 100%;
		overflow:hidden;
		z-index:0;
	}

	lazyimage{
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		background: url(#{$imgs}/loading.svg) center center no-repeat;
		background:#000;
	}

	img{
		width:100%;
	}

	figcaption{
		position:absolute;
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		z-index:2;
		top:0;
		background: rgba(#000,0.6);
		color:#FFF;
		text-align:center;
		transition:opacity 0.6s linear;
		opacity: 1;
	}

	.cat{
		font-size:18px;
	}

	.nome{
		font-size:26px;
		font-family: 'open_sansbold';
		margin-bottom:5px;
	}

	.outline{
		display: inline-block;
		padding:6px 12px;
		border:1px solid #FFF;
		font-size:16px;
	}

	.album:hover{
		figcaption{
			opacity:0;
		}
	}
}

.creditos{
	border-top: 2px solid #000000;
	padding-top:10px;
	font-size:12px;
}

.gv8{
	display: inline-block;
	width:66px;
	height:21px;
	background: url(#{$imgs}/gv8.png) center center;
	margin-left:10px;
}
