$icons : (
	'banner-next' : -184px 0px 38px 33px,
	'banner-prev' : -184px -43px 38px 33px,
	'br' : -59px -125px 48px 33px,
	'en' : -117px -125px 48px 33px,
	'es' : 0px -125px 49px 33px,
	'goToTop' : 0px 0px 48px 51px,
	'next-categoria' : -58px 0px 32px 54px,
	'next-estrutura' : 0px -61px 32px 54px,
	'next-sm-dark' : -184px -124px 18px 28px,
	'next-sm' : -184px -86px 18px 28px,
	'prev-categoria' : -142px 0px 32px 54px,
	'prev-estrutura' : -100px 0px 32px 54px,
	'prev-sm-dark' : -142px -64px 18px 28px,
	'prev-sm' : -100px -64px 18px 28px,

);

.icon{
	background-image:  url(#{$imgs}/sprite.png);
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 222px 158px;
}

@mixin sprites($icons) {
	@each $icon, $data in $icons {
		.#{$icon} {
		 	width: nth($data,3);
		 	min-width: nth($data,3);
            height: nth($data,4);
            min-height: nth($data,4);
            background-position: #{nth($data,1)} #{nth($data,2)};
		}
	}
}

@include sprites($icons);
