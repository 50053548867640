@media (min-width: $screen-sm-min) and (max-width:$screen-sm-max){
	// Teblet -   > 768; < 992

	#banner{
		text-align:center;
		.titulo{
			margin-top:30px;
			margin-bottom:10px;
		}
	}

	.menu{
		.heading{
			border-bottom:1px solid rgba(#000,0.2);
		}

		.active a,
		li:hover a{
			background: #FFF;
			color: #C51D21;
		}

		#menu{
			padding-bottom:5px;
		}
	}

	.flx-footer{
		justify-content:center;

		p{
			width:100%;
		}

		p:last-child{
			margin-bottom:20px;
		}

		.circle-floating{
			& +.circle-floating{
				margin-left: 10px;
			}
		}
	}

	.menu{
		top:0;
	}

}

@media (max-width:$screen-sm-max){
	.owl-fluid-menu{
		.owl-nav{
			padding-top:20px;
		}
	}

	.creditos{
		text-align:center;
	}

	.gv8{
		display: block !important;
		margin-top:10px;
		margin-left:auto !important;
		margin-right:auto !important;
	}

	#estrutura{
		
		.owl-controls{
			text-align:center;
		}

		.owl-nav{
			width:100%;
			@include clearfix;
			margin-top:30px;
		}

		.owl-prev{
			cursor:pointer;
			display: inline-block;
			margin:20px 10px;
		}

		.owl-next{
			cursor:pointer;
			display: inline-block;
			margin:20px 10px;
		}
	}
}


