.section{
	// min-height: calc(100vh - 100px);
}

.bg-grey{
	background: #444444;
	color:#fff;
}



.form-control{
	border-radius:0;
	background: #DDDDDD;
}

.owl-prev,
.owl-next{
	cursor:pointer;
}

.internas{
	padding-top:20px;
	padding-bottom:2px;
	position:relative;

	.box-logo-internas{
		margin-bottom:28px;
		text-align:center;
	}

	#navFotos{
		line-height:28px;
	}

	.owl-fluid-menu .owl-next{
		.icon{@extend .next-sm-dark;}
	}

	.owl-fluid-menu .owl-prev{
		.icon{@extend .prev-sm-dark;}
	}
}


.topo-internas{
	text-align:center;
	padding:15px;
	font-family: 'open_sansbold';
	text-shadow: 0 0 3px rgba(#000,0.6);
}

.img-responsive{
	display: inline-block;
}

.btn-solicitar-orcamento{
	display: block;
	padding:10px;
	border-left:2px solid #333333;
	border-right:2px solid #333333;
	text-align:center;

	&:hover{
		background:#eee;
		text-decoration:none;
		border-color: #C51D21;
	}
}

.redes-sociais-galeria{
	&:before,
	&:after{
		content:'';
		display:block;
		height:2px;
		background:#ccc;
	}

	.row{
		padding-top:10px;
		padding-bottom:10px;
		display:flex;
		flex-wrap:wrap;
	}

	.circle{
		display: inline-block;
		width:30px;
		height:30px;
		background:#BBBBBB;
		border-radius:100%;
		line-height:30px;
		text-align:center;
		color:#FFF;

		&:hover{
			text-decoration:none;
		}
	}
}

.goToTop{
	cursor:pointer;
}