.post{
	margin-bottom: 15px;

	.titulo{
		font-family: 'open_sansbold';
		font-size: 18px;
	}

	& + .post{
		padding-top: 15px;
		border-top: 1px solid #CCC;
	}

	.desc{
		display: -webkit-box;
		-webkit-box-orient:vertical;
		-webkit-line-clamp: 3;
		max-height: 4.5em;
		overflow: hidden;
		line-height: 1.5em;
		margin-bottom: 10px;
	}

	.saiba{
		font-family: 'open_sansbold';

		&:hover{
			color: #C51D21;
			text-decoration: none;
		}
	}
}


.post-detalhes{
	@for $i from 1 through 6{
		h#{$i} {
			font-family: 'open_sansbold';
		}
	}

	img{
		max-width: 100%;
		height: auto;
	}
}
