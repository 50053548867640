body{
	font-family: 'open_sansregular';
}


a{
	color:inherit;
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.h1{
	font-family: 'open_sansbold';
	text-shadow: 0 0 1px rgba(#fff,0);
	// font-size:40px;

	.tab{
		display: block;
		color: #BABABA;
		font-size: (46 / 64);

		&.tab1{
			margin-left:1em;
		}
	}
}

.text-white{
	color:#FFF;
}

.h2{
	font-size:40px;
	color: #BABABA;
	font-family: 'open_sansbold';
}

.text-danger{
	color: #C51D20;
}

.header-galeria{
	text-align:center;

	.categoria{
		font-size:18px;
		margin-bottom:5px;
	}

	.titulo{
		font-size:26px;
		font-family: 'open_sansbold';
	}

	.adorno{
		display: block;
		height:18px;
		background: url(#{$imgs}/adorno.png) center top no-repeat;
		margin-top:10px;
	}
}

.trabalhe-conosco{
	font-size:44px;
}