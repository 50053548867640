#videos{

	.video{
		background-position:center center;
		background-repeat:no-repeat;
		background-size:cover;
		padding-bottom: (361 / 640) * 100%;
		cursor:pointer;
		position:relative;
		transform: scale(1);
		transition:all 0.3s linear;

		&:before{
			display:flex;
			align-items:center;
			justify-content: center;
			// content:'';
			// font-family:FontAwesome;
			font-size:42px;
			color:#FFF;
			position:absolute;
			width:100%;
			height:100%;
			text-shadow:0 0 4px rgba(#000,0.5);
		}
	}
}

#videoAmpliado{
	.close{

	}
	& *{
		box-shadow:none !important;
		border:0;
		background:0;
	}

	.modal-body,
	.modal-header{
		padding:0;
	}

	.modal-dialog{
		width:900px;
		max-width:100%;
	}
}

videocartaz{
	display: block;
	padding-bottom: (361 / 640) * 100%;
	position:relative;

	iframe{
		position:absolute;
		width:100%;
		height:100%;
	}
}