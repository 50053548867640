.topo{
	position:absolute;
	width:100%;
	z-index:2;
	text-align:center;
	font-family: 'open_sansbold';
	color:#FFF;
	text-shadow: 0 0 4px rgba(#000,0.8);
	padding:10px 0;

	.endereco{
		margin-bottom:10px;
	}
}

.brand{
	img{
		display: inline-block;
	}
}

.menu{
	background: #C51D21;
	font-size:16px;
	position:relative;
	width:100%;
	z-index:999;

	&.fx,
	&.affix{
		position:fixed;
		top:0;
		margin-top:0;
		box-shadow:0 0 8px rgba(#000,0.8);
	}

	.btn{
		border-radius: 0;
		padding-top:10px;
		padding-bottom:10px;
		@include button-variant(#FFF,#C51D21,#C51D21);
		border-right-color:rgba(#000,0.2);
		font-family: 'open_sansbold';
		font-size:16px;
	}
}

#menu{
	list-style:none;
	padding:0;
	margin-bottom:0;

	font-family: 'open_sansbold';

	&.nav>li>a{
		padding: 10px 10px;
	}
}
