.paginacao{
	margin-top:20px;
	margin-bottom:30px;

	> *{
		display: inline-block;
		border:1px solid rgba(#000,0.2);
		padding:6px 12px;
		background:#fff;

		&:first-child{
			border-top-left-radius:4px;
			border-bottom-left-radius:4px;
		}

		&:last-child{
			border-top-right-radius:4px;
			border-bottom-right-radius:4px;
		}
	}

	.active,
	*:hover{
		$color-default:#fff;
		text-decoration:none;
		background: $brand-primary;
		color: $color-default;
	}
}
