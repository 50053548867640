.index{
	position:relative;
}

.section{
	padding-top:30px;
	padding-bottom:30px;
}

#quem_somos,
#galeria_de_videos,
#galeria_de_fotos{
	min-height: calc(100vh - 100px);
}

#estrutura{
	background: #C51D21;
	color:#FFF;
	padding:30px 0;
	

	.h1{
		margin-bottom:30px;
	}

	.owl-stage-outer{
		z-index: 1;
	}

	img{
		margin-left:auto;
		margin-right:auto;
	}
}

#categoriasIndex{
	.categoria{
		position:relative;
		width:319px;
		max-width:100%;
		margin-left:auto;
		margin-right:auto;

		&:hover{
			lazyimage{
				&.color{
					opacity:1;
				}
				&.grey{
					opacity:0;
				}
			}
		}
	}

	lazyimage{
		transition:all 0.3s linear;
		width:100%;
		display: block;
	}

	lazyimage.color{
		position:absolute;
		width:100%;
		top:0;
		z-index:0;
		opacity:0;
	}

	lazyimage.grey{
		position:relative;
		z-index:1;
		opacity:1;
	}

	figcaption{
		width:100%;
		min-height:50%;
		position:absolute;
		left:0;
		bottom:0;
		padding-left:15px;
		padding-right:15px;
		padding-bottom:15px;
		color:#FFF;
		z-index:3;
		text-align:center;
		font-family: 'open_sansbold';
		font-size:28px;
		text-shadow:0 0 4px rgba(#000,0.65);
	}

	.owl-nav > *{
		position:absolute;
		bottom:10px;
		cursor:pointer;
	}

	.owl-prev{
		left:0;
	}

	.owl-next{
		right:0;
	}
}

.owl-fluid-menu{
	white-space:nowrap;
	margin-left:-15px;
	margin-right:-15px;
	font-family: 'open_sansbold';
	min-height:50px;

	.nav-videos{
		padding:0 10px;
	}

	.owl-nav > *{
		display: inline-block;
		cursor:pointer;

		& + *{
			margin-left:10px;
		}
	}
}

.owl-videos-content{
	margin-left:-15px;
	margin-right:-15px;

	.item{
		padding:0 15px;
	}

	img{
		cursor:pointer;
	}

	.owl-nav{
		@include clearfix;
		padding:10px 15px;

		& > *{
			display: inline-block;
		}

		.owl-prev{
			float:left;
		}

		.owl-next{
			float:right;
		}
	}
}
.empresa__sub{
	font-family: open_sansbold;
	margin-bottom: 8px;
	font-size: 30px;
	line-height: 34px;
	color: #333333;
}
.empresa__desc{
	margin-bottom: 40px;
}
.empresa__grid{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;
	row-gap: 30px;
	margin-bottom: 40px;

	@media (max-width: 930px) {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
		row-gap: 20px;
	}
}
.emp__card{
	display: block;
	width: 100%;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
}
