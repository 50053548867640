@media (max-width:$screen-xs-max){
	// Celphones - < 768
	.topo{
		position:relative !important;
		background: #C51D21;
		padding-bottom:10px;
	}
	.menu{
		.heading{
			border-bottom:1px solid rgba(#000,0.2);
		}

		.active a,
		li:hover a{
			background: #FFF;
			color: #C51D21;
		}

		#menu{
			padding-bottom:5px;

			a{
				color:#FFF;
			}

			&.active a,
			a:hover{
				color: #666;
			}
		}
	}

	#estrutura{
		.owl-nav{
			position:relative !important;
			top:0;
			text-align:center;
			width:100%;

			& > *{
				float:none !important;

			}
		}
	}

	.section{
		padding:30px 15px;
		// min-height: calc(100vh - 45px);
	}

	.flx-footer{
		justify-content:center;

		p{
			width:100%;
		}

		p:last-child{
			margin-bottom:20px;
		}

		.circle-floating{
			& +.circle-floating{
				margin-left: 10px;
			}
		}
	}

	.menu.affix{
		top:0;
	}

	.redes-sociais-galeria{
		text-align:center;

		.col-md-6{
			width:100%;

			& + .col-md-6{
				margin-top:10px;
			}
		}
	}

	.btn-solicitar-orcamento{
		margin-top:10px;
	}


	#videos{
		.owl-item{
			padding:0 15px;
		}
	}
}