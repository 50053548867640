@media (min-width:$screen-md-min){
	// Desktop - >= 990px
	.brand{
		@include clearfix;
		img{
			float:left;
		}
	}

	#banner{
		margin-bottom:100px;
	}

	.menu{
		// margin-top:-100px;

		.heading{
			display: none;
		}
	}

	#menu{
		display:flex;
		justify-content: center;
		white-space:nowrap;

		a{
			background: none !important;
			color:#FFF;
			height:100px;
			display:flex;
			align-items:center;

			&:hover{
				color:#FFF;
			}
		}

		.active a,
		li:hover a,
		a:focus{
			color: #999999;
			text-decoration:underline;
		}
	}

	.h1{
		font-size:64px;
	}

	.owl-fluid-menu{
		padding-left:100px;

		.owl-nav{
			position:absolute;
			width:100px;
			left:0;
			top:0;
		}
	}

	.rodape{
		.h1{
			span{
				display: block;
				padding-left:1em;
			}
		}
	}

	.flx-footer{
		p{
			width: calc(100% - 30px);
			padding-right:30px;
		}
		@for $i from 1 through 6 {
			.ord-#{$i}{
				order: $i;
			}
		}
	}

	.creditos{
		.col-md-6:last-child{
			text-align:right;
		}
	}

	.redes-sociais-galeria{
		.col-md-6:first-child{
			padding-top:5px;
		}
		.col-md-6:last-child{
			text-align:right;
		}
	}

	.section{
		display:flex;
		align-items:center;
		justify-content:center;
	}

	#estrutura{
		
		.owl-controls{
			position:absolute;
			width:100%;
			height:100%;
			display:flex;
			align-items:center;
			top:0;
			left:0;
		}

		.owl-nav{
			width:100%;
			@include clearfix;
		}

		.owl-prev{
			float:left;
			position:relative;
			z-index:2;
			cursor:pointer;
		}

		.owl-next{
			float:right;
			position:relative;
			z-index:2;
			cursor:pointer;
		}
	}
}